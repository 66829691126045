// import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

// const isValidToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }
//
//   const decoded = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//
//   return decoded.exp > currentTime;
// };

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

// ----------------------------------------------------------------------

// function jwtDecode(token) {
//   console.log(token);
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   const jsonPayload = decodeURIComponent(
//     window
//       .atob(base64)
//       .split('')
//       .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
//       .join('')
//   );
//
//   return JSON.parse(jsonPayload);
// }

// ----------------------------------------------------------------------

// export const isValidToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }
//
//   const decoded = jwtDecode(accessToken);
//
//   const currentTime = Date.now() / 1000;
//
//   return decoded.exp > currentTime;
// };

// ----------------------------------------------------------------------

// export const tokenExpired = (exp) => {
//   // eslint-disable-next-line prefer-const
//   let expiredTimer;
//
//   const currentTime = Date.now();
//
//   // Test token expires after 10s
//   // const timeLeft = currentTime + 10000 - currentTime; // ~10s
//   const timeLeft = exp * 1000 - currentTime;
//
//   clearTimeout(expiredTimer);
//
//   expiredTimer = setTimeout(() => {
//     alert('Token expired');
//
//     sessionStorage.removeItem('authParams');
//
//     window.location.href = PATH_AUTH.login;
//   }, timeLeft);
// };

// ----------------------------------------------------------------------

const setSession = (params) => {
  if (params) {
    localStorage.setItem('authParams', JSON.stringify(params));
    axios.defaults.headers.common.Authorization = `Bearer ${params.tokens.tokenType}`;
    axios.defaults.headers.common['access-token'] = params.tokens.accessToken;
    axios.defaults.headers.common['token-type'] = params.tokens.tokenType;
    axios.defaults.headers.common.client = params.tokens.client;
    axios.defaults.headers.common.expiry = params.tokens.expiry;
    axios.defaults.headers.common.uid = params.tokens.uid;
    // This function below will handle when token is expired
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(params.tokens.accessToken); // ~3 days by minimals server
    // tokenExpired(exp);
  } else {
    localStorage.removeItem('authParams');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common['access-token'];
    delete axios.defaults.headers.common['token-type'];
    delete axios.defaults.headers.common.client;
    delete axios.defaults.headers.common.expiry;
    delete axios.defaults.headers.common.uid;
  }
};

export { setSession, verify, sign };
